import { themeGet } from 'styled-system'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-content: space-between;
  background-color: ${themeGet('globalNav.backgroundColor')};
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  position: relative;
  z-index: 1;
`

export const Left = styled.div`
  display: flex;
  flex-direction: row;
`

export const LogoContainer = styled.a`
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  :hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

export const Logo = styled.img`
  filter: brightness(0) invert(1);
  height: 22px;
  width: 18px;
  margin-left: 20px;
  margin-right: 20px;
  object-fit: contain;
`

export const Divider = styled.div`
  border-right: 1px;
  border-right-color: #d8d8d8;
  border-right-style: solid;
  opacity: 20%;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`

export const Title = styled.div`
  color: white;
  font-family: 'SharpSlab-Bold';
  font-size: 18px;
`

export const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`

export const Icons = styled.div`
  flex-direction: row;
`

export const IconContainer = styled.button`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 5px;
  border: none;

  :hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

export const Icon = styled.img`
  filter: brightness(0) invert(1);
  height: auto;
  margin: auto;
  object-fit: contain;
  width: auto;
  vertical-align: sub;
`

export const Badge = styled.span`
  background-color: ${props => props.backgroundColor || '#F3544C'};
  border-color: ${props => props.borderColor};
  border-radius: 8.5px;
  border-width: 2px;
  color: white;
  font-family: 'Molde-Medium';
  font-size: 11px;
  height: 17px;
  position: absolute;
  right: -4px;
  text-align: center;
  top: 0;
  width: 17px;
`
