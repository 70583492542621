import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  ContainedButton,
  ModalContentWrapper,
  GearIcon,
} from 'secondstep-components'
import { AutoTable } from 'secondstep-components'
import Container from '../../components/Container'
import { LUMModal } from '../../components/LUMModal/component.styles'
import {
  ButtonsContainer,
  RootWrapper,
} from '../../components/SendInvitesDialog/component.styles'

export const TextLinkContainer = styled(Container).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 1.25rem 1.25rem 0.6875rem;
`

export const StyledTextLinkDashboard = styled.a.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  font-family: ${themeGet('fontFamily')};
  color: ${themeGet('colors.darkGray')};
  text-decoration: none;

  &:hover {
    color: ${themeGet('colors.secondStepBlue')};
  }
`

export const StyledTextLinkLicenseManagement = styled.a.attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
  }),
)`
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1rem;
  color: ${themeGet('colors.blue')};
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:hover svg {
    color: ${themeGet('colors.secondStepBlue')};
  }
`

export const StyledGearIcon = styled(GearIcon)`
  color: ${themeGet('colors.blue')};
  margin-right: 0.5rem;
  margin-top: -0.2188rem;
`

export const AddUserModalContainer = styled(LUMModal)`
  .Overlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  ${ModalContentWrapper} {
    width: fit-content;

    @media screen and (max-width: 730px) {
      width: 21.4375rem;
    }

    @media screen and (max-height: 760px) {
      max-height: 45rem;
      overflow-y: auto;
    }

    @media screen and (max-height: 740px) {
      max-height: 41rem;
    }
  }
`

export const SitePickerContainer = styled(Container)`
  padding: 0 1.25rem 1.5rem;
`

export const TableContainer = styled(Container)`
  position: relative;
  overflow: visible;
  padding: 0;
  margin: 0 1.5rem 0.75rem;
  width: auto;
  height: auto;

  ${AutoTable} {
    border: 0.13rem solid ${themeGet('colors.borderTable')};
    border-radius: 0.6rem;
    border-spacing: 0;
    border-collapse: separate;
  }

  th {
    background-color: ${themeGet('colors.borderTable')};
  }

  tr {
    box-shadow: 0 0 0.13rem ${themeGet('colors.borderTable')};
  }

  th:first-of-type {
    border-top-left-radius: 0.4rem;
  }

  th:last-of-type {
    border-top-right-radius: 0.4rem;
  }

  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 0.6rem;
  }

  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 0.6rem;
  }
`

export const RemindUsersModal = styled(LUMModal)`
  ${RootWrapper} {
    width: 100%;
  }

  ${ModalContentWrapper} {
    width: 29.688rem;

    @media screen and (max-width: 29.6875rem) {
      width: 93%;
    }
  }

  ${ButtonsContainer} {
    @media screen and (max-width: 23.4375rem) {
      flex-direction: row;
    }
  }

  ${ContainedButton} {
    @media screen and (max-width: 23.4375rem) {
      padding: 0.25rem 2.25rem 0 2.25rem;
    }
  }
`

export const LoadingWrapper = styled.div`
  position: absolute;
  left: 42.75%;
  top: 20%;
`

export const SiteUserGridWrapper = styled.div.attrs(({ isUpdating }) => ({
  blur: isUpdating ? 'blur(2px)' : 'none',
}))`
  filter: ${({ blur }) => blur};
`

export const UserInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 1.5rem;

  [class*='InviteAndRemindContainer'] {
    margin-bottom: 0.5rem;
  }
`

export const UserCountFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;

  [class*='FilterWrapper'] {
    margin-top: 1rem;
  }
`
