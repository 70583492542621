import React, { useEffect, useState } from 'react'
import FlagsContext from '../../FlagsSetup/FlagsContext'
import FlagsSingleton from '../../FlagsManager'

export default function FlagsComponentRouter({
  flagSubscriptions = [],
  OnComponent,
  OffComponent,
  onPropsOverrides = {},
  offPropsOverrides = {},
  flagsChecker = FlagsSingleton.checkAllFlagValuesTrue,
  // Params for onCondition are consuming component props and subscribedFlags
  onCondition = () => true,
}) {
  return React.forwardRef(function FlagRouterHOC(props, ref) {
    const [ needsRemount, setNeedsRemount ] = useState(false)
    useEffect(() => {
      if (needsRemount) {
        // This is a workaround to force a remount of the component via its child
        // in order to re-evaluate the flags, especially if their onCondition depends on routing
        setNeedsRemount(false)
      }
    }, [needsRemount])
  
    function requestRemount() {
      setNeedsRemount(true)
    }
    
    return (
      <FlagsContext.Consumer>
        {({ flags }) => {
          const subscribedFlags = FlagsSingleton.getUpdatedSubscribedFlags(
            flags,
            flagSubscriptions,
          )
          const flagsChecked = flagsChecker(subscribedFlags)
          const shouldShowOnComponent =
            flagsChecked && onCondition(props, subscribedFlags)

          if (shouldShowOnComponent) {
            return (
              <OnComponent
                {...props}
                {...onPropsOverrides}
                ref={ref}
                requestRemount={requestRemount}
              />
            )
          } else {
            return (
              <OffComponent
                {...props}
                {...offPropsOverrides}
                ref={ref}
                requestRemount={requestRemount}
              />
            )
          }
        }}
      </FlagsContext.Consumer>
    )
  })
}
