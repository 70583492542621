import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderTwo } from 'secondstep-components'
import { Field } from 'formik'

export const Header = styled(HeaderTwo)`
  font-size: 1.625rem;
  padding-bottom: 0.4375rem;
  font-family: ${themeGet('headerFontFamilySemibold')};

  ${themeGet('breakpoints.tablet')} {
    margin-bottom: 0;
    text-align: center;
  }
`

export const EmailInputWrapper = styled.div`
  margin-bottom: 1.3125rem;
`

export const InputLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8125rem;
  color: ${themeGet('colors.darkGray')};
`

export const InputLabel = styled.label`
  font-family: ${themeGet('fontFamilySemibold')};
`

export const BulkUsersInfoDiv = styled.div`
  font-family: ${themeGet('fontFamily')};
`

export const EmailInputField = styled(Field).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  height: 2.6875rem;
  width: 100%;
  border: 0.0938rem solid;
  border-color: ${props =>
    themeGet(props.displayerror === 'true' ? 'colors.error' : 'colors.blue')};
  border-radius: 0.3125rem;
  box-sizing: border-box;
  padding: 1rem 4rem 0.875rem 0.9375rem;
  font-family: ${themeGet('fontFamily')};

  &::placeholder {
    font-size: 1rem;
    color: ${themeGet('colors.mediumGray')};
    text-align: left;
    letter-spacing: 0;
    line-height: 1.25rem;
    font-style: normal;
  }

  &:focus-visible {
    outline: none;
    border: 0.0938rem solid;
    border-color: ${props =>
      themeGet(
        props.displayerror === 'true'
          ? 'colors.error'
          : 'colors.secondStepBlue',
      )};
  }

  @media (max-height: 790px) {
    height: 4rem;
  }
`
export const EmailTextAreaField = styled(EmailInputField).attrs(
    ({ dataTestId }) => ({
      'data-testid': dataTestId,
    }),
  )`
  height: 16.0625rem;
  resize: none;
  padding-right: 1rem;
`

export const InputModeToggle = styled.button.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  border: none;
  background: none;
  width: fit-content;
  padding: 0;
  cursor: pointer;
  color: ${themeGet('colors.blue')};
  font-family: ${themeGet('fontFamilySemibold')};
  text-align: left;
  margin-top: 1.1875rem;
  &:hover {
    outline: none;
    color: ${themeGet('colors.secondStepBlue')};
  }
`

export const TextInputContainer = styled.div`
  position: relative;
`

export const Error = styled.div`
  margin-top: 0.25rem;
  font-family: ${themeGet('fontFamily')};
  color: ${themeGet('colors.error')};
`
