import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'
import {
  BulkUsersInfoDiv,
  EmailInputField,
  EmailInputWrapper,
  EmailTextAreaField,
  Error,
  Header,
  InputLabel,
  InputLabelContainer,
  InputModeToggle,
  TextInputContainer,
} from './component.styles'
import {
  ADD_MULTIPLE_USERS_HEADER,
  ADD_SINGLE_USER_HEADER,
  BULK_EMAIL_LABEL,
  BULK_USERS_INFO_TEXT,
  EMAIL_INPUT_PLACEHOLDER,
  SINGLE_EMAIL_LABEL,
} from './constant'
import { inputModeType } from 'lib/util'
import { CharacterCounter } from 'secondstep-components'

const AddUserModalInputText = ({
  errors,
  handleInputModeToggle,
  inputMode,
  values,
}) => {
  const header =
    inputMode === inputModeType.single
      ? ADD_SINGLE_USER_HEADER
      : ADD_MULTIPLE_USERS_HEADER
  const inputModeToggleText =
    inputMode === inputModeType.single
      ? ADD_MULTIPLE_USERS_HEADER
      : ADD_SINGLE_USER_HEADER
  const emailAddressLabel =
    inputMode === inputModeType.single ? SINGLE_EMAIL_LABEL : BULK_EMAIL_LABEL

  const { emails } = values
  const { single, bulk } = inputModeType
  const displayError = Object.keys(errors).length === 0 ? 'false' : 'true'

  return (
    <EmailInputWrapper>
      <Header>{header}</Header>
      <InputLabelContainer>
        <InputLabel htmlFor="emails">{emailAddressLabel}</InputLabel>
        {inputMode === bulk && (
          <BulkUsersInfoDiv>{BULK_USERS_INFO_TEXT}</BulkUsersInfoDiv>
        )}
      </InputLabelContainer>
      {inputMode === single ? (
        <TextInputContainer>
          <EmailInputField
            dataTestId="single-user-input"
            displayerror={displayError}
            maxLength={256}
            name="emails"
            type="email"
            value={emails}
          />
          <CharacterCounter
            charValue={emails}
            dataTestId="single-user-input"
            label="single-user-input"
            maxLength={256}
          />
        </TextInputContainer>
      ) : (
        <EmailTextAreaField
          cols="50"
          dataTestId="bulk-user-input"
          component="textarea"
          displayerror={displayError}
          id="emails"
          name="emails"
          placeholder={EMAIL_INPUT_PLACEHOLDER}
          rows="11"
          values={emails}
        />
      )}
      <ErrorMessage component={Error} name="emails" />
      <InputModeToggle
        onClick={handleInputModeToggle}
        dataTestId={
          inputMode === inputModeType.single
            ? 'text-link-add-bulk-user'
            : 'text-link-add-single-user'
        }
        type="button"
      >
        {inputModeToggleText}
      </InputModeToggle>
    </EmailInputWrapper>
  )
}

AddUserModalInputText.propTypes = {
  errors: PropTypes.object,
  handleInputModeToggle: PropTypes.func,
  inputMode: PropTypes.string,
  values: PropTypes.shape({
    emails: PropTypes.string,
  }),
}

export default AddUserModalInputText
