import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AddUserModalForm from './component'
import StutterStepModal from '../StutterStepModal'
import { Formik, Form } from 'formik'
import { singleAddUserSchema, bulkAddUserSchema } from '../../lib/schemas'
import { AddUsersRolesInfo, inputModeType } from 'lib/util'

import {
  DATA_LOSS_SCREEN_TEXT_SINGLE_USER,
  DATA_LOSS_SCREEN_HEADER_SINGLE_USER,
  DATA_LOSS_SCREEN_TEXT_MULTIPLE_USERS,
  DATA_LOSS_SCREEN_HEADER_MULTIPLE_USERS,
} from './constants'

const AddUserModalFormContainer = ({
  handleCloseAddUsersModal,
  handleSubmitAddUsers,
  selectedSite,
}) => {
  const [openStutterModal, setOpenStutterModal] = useState(false)
  const [inputMode, setInputMode] = useState(inputModeType.single)
  const [submitClicked, setSubmitClicked] = useState(false)

  const hasADLSkuCode = selectedSite.licenses.some(
    license => license.product.skuCode === 'ADL',
  )

  const rolesInfo = hasADLSkuCode
    ? AddUsersRolesInfo.ADL
    : AddUsersRolesInfo.Default

  const handleCloseModal = resetForm => {
    resetForm()
    handleCloseAddUsersModal()
  }

  const onCancelStutterModal = () => setOpenStutterModal(false)

  const onConfirmStutterModal = (emails, resetForm) => {
    onCancelStutterModal()
    handleInputModeToggle(emails, resetForm, true)
  }

  const handleInputModeToggle = (emails, resetForm, confirmClicked = false) => {
    if (emails?.length === 0 || confirmClicked) {
      if (inputMode === inputModeType.single) {
        setInputMode(inputModeType.bulk)
      } else {
        setInputMode(inputModeType.single)
      }
      setSubmitClicked(false)
      resetForm()
    } else {
      setOpenStutterModal(true)
    }
  }

  const handleRoleSelect = (setFieldValue, roleId) => {
    if (roleId !== 'Admin') return
    setFieldValue('sendNotification', 'true')
  }

  const validationSchema =
    inputMode === inputModeType.single ? singleAddUserSchema : bulkAddUserSchema

  return (
    <Formik
      context={{ inputMode }}
      initialValues={{
        emails: '',
        selectedRole: 'Teacher',
        sendNotification: 'true',
      }}
      onSubmit={(values, { setSubmitting }) => {
        const emailArray = values.emails
          .toLowerCase()
          .split(/[,;\n]+/)
          .map(email => email.trim())
        const sendNotification = values.sendNotification === 'true'

        handleSubmitAddUsers(
          emailArray,
          values.selectedRole,
          sendNotification,
          inputMode,
        )
        setSubmitting(false)
      }}
      validateOnBlur={false}
      validateOnChange={submitClicked}
      validationSchema={validationSchema}
    >
      {({ values, errors, resetForm, isSubmitting, setFieldValue }) => (
        <Form>
          <AddUserModalForm
            errors={errors}
            handleCloseModal={() => handleCloseModal(resetForm)}
            handleInputModeToggle={() =>
              handleInputModeToggle(values.emails, resetForm, false)
            }
            handleRoleSelect={handleRoleSelect}
            inputMode={inputMode}
            isSubmitting={isSubmitting}
            rolesInfo={rolesInfo}
            setFieldValue={setFieldValue}
            setInputMode={setInputMode}
            setSubmitClicked={setSubmitClicked}
            values={values}
          />
          <StutterStepModal
            header={
              inputMode === inputModeType.single
                ? DATA_LOSS_SCREEN_HEADER_MULTIPLE_USERS
                : DATA_LOSS_SCREEN_HEADER_SINGLE_USER
            }
            isOpen={openStutterModal}
            onCancel={onCancelStutterModal}
            onConfirm={() => onConfirmStutterModal(values.emails, resetForm)}
            text={
              inputMode === inputModeType.single
                ? DATA_LOSS_SCREEN_TEXT_MULTIPLE_USERS
                : DATA_LOSS_SCREEN_TEXT_SINGLE_USER
            }
          />
        </Form>
      )}
    </Formik>
  )
}

AddUserModalFormContainer.propTypes = {
  handleCloseAddUsersModal: PropTypes.func,
  handleSubmitAddUsers: PropTypes.func,
  selectedSite: PropTypes.object,
}

export default AddUserModalFormContainer
