import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  BoldParagraph,
  BottomParagraph,
  BottomSquareIcon,
  CheckIcon,
  CloseButton,
  CopyWrapper,
  EmailsTextArea,
  ExclamationIcon,
  Header,
  IconWrapper,
  InnerWrapper,
  MessageWrapper,
  Paragraph,
  SecondaryWrapper,
  SecondEmailsTextArea,
  SmallParagraph,
  SquareIcon,
  Wrapper,
} from './component.styles'
import { pluralize } from 'lib/util'

const AddUserModalSummary = ({ data, onClose }) => {
  const [copyStatusInvalid, setCopyStatusInvalid] = useState('Copy text')
  const [copyStatusFailed, setCopyStatusFailed] = useState('Copy text')
  const { newSiteUsers, failedSiteUsers, invalidSiteUsers, sendEmail } = data

  const newSiteUserEmails = newSiteUsers.map(user => user.inviteEmail)
  const invalidSiteUserEmails = invalidSiteUsers.map(user => user.inviteEmail)
  const failedSiteUserEmails = failedSiteUsers.map(user => user.inviteEmail)

  const countOfValidUsers = newSiteUserEmails?.length || 0
  const countOfFailedUsers = failedSiteUserEmails?.length || 0
  const countOfInvalidUsers = invalidSiteUserEmails?.length || 0
  const countOfTotalFailedUsers = countOfFailedUsers + countOfInvalidUsers
  const countOfTotalEntries = countOfValidUsers + countOfTotalFailedUsers

  const copyToClipboard = (text, updater) => () =>
    navigator.clipboard
      .writeText(text)
      .then(updater('Copied!'))
      .finally(
        setTimeout(
          () => updater(value => (value === 'Copied!' ? 'Copy text' : value)),
          5000,
        ),
      )

  return (
    <Wrapper>
      <Header>
        {countOfTotalEntries}{' '}
        {pluralize(countOfTotalEntries, 'email address', 'email addresses')}{' '}
        entered
      </Header>
      <MessageWrapper>
        <CheckIcon dataTestId="add-modal-summary-check-icon" />
        <BoldParagraph data-testid="add-modal-summary-success-message">
          {!sendEmail
            ? `${countOfValidUsers} ${pluralize(
                countOfValidUsers,
                'user',
              )} ${pluralize(countOfValidUsers, 'was', 'were')} added`
            : `${countOfValidUsers} email
          ${pluralize(countOfValidUsers, 'notification')} sent`}
        </BoldParagraph>
      </MessageWrapper>
      {!!countOfTotalFailedUsers && (
        <>
          <MessageWrapper>
            <ExclamationIcon dataTestId="add-modal-summary-invalid-exclamation-icon" />
            <BoldParagraph data-testid="add-modal-summary-success-message">
              {countOfTotalFailedUsers}{' '}
              {pluralize(countOfTotalFailedUsers, 'error')}{' '}
              {pluralize(countOfTotalFailedUsers, 'was', 'were')} found
            </BoldParagraph>
          </MessageWrapper>
          {!!countOfInvalidUsers && (
            <InnerWrapper>
              <SecondaryWrapper>
                <Paragraph data-testid="add-modal-summary-invalid-message">
                  {countOfInvalidUsers} email{' '}
                  {pluralize(countOfInvalidUsers, 'address', 'addresses')}{' '}
                  {pluralize(countOfInvalidUsers, 'doesn’t', 'don’t')} look
                  valid:
                </Paragraph>
                <CopyWrapper
                  onClick={copyToClipboard(
                    invalidSiteUserEmails?.join(',\n'),
                    setCopyStatusInvalid,
                  )}
                >
                  <SmallParagraph>{copyStatusInvalid}</SmallParagraph>
                  <IconWrapper>
                    <SquareIcon />
                    <BottomSquareIcon />
                  </IconWrapper>
                </CopyWrapper>
              </SecondaryWrapper>
              <EmailsTextArea
                dataTestId="add-modal-summary-invalid-textarea"
                value={invalidSiteUserEmails?.join(',\n')}
              />
            </InnerWrapper>
          )}
          {!!countOfFailedUsers && (
            <InnerWrapper>
              <SecondaryWrapper>
                <Paragraph data-testid="add-modal-summary-failed-message">
                  {countOfFailedUsers} email{' '}
                  {pluralize(countOfFailedUsers, 'address', 'addresses')}{' '}
                  couldn’t be accepted:
                </Paragraph>
                <CopyWrapper
                  onClick={copyToClipboard(
                    failedSiteUserEmails?.join(',\n'),
                    setCopyStatusFailed,
                  )}
                >
                  <SmallParagraph>{copyStatusFailed}</SmallParagraph>
                  <IconWrapper>
                    <SquareIcon />
                    <BottomSquareIcon />
                  </IconWrapper>
                </CopyWrapper>
              </SecondaryWrapper>
              <SecondEmailsTextArea
                dataTestId="add-modal-summary-failed-textarea"
                value={failedSiteUserEmails?.join(',\n')}
              />
              <BottomParagraph>
                {pluralize(countOfFailedUsers, 'This', 'These')} email{' '}
                {pluralize(countOfFailedUsers, 'address', 'addresses')} may
                already be on the site.
              </BottomParagraph>
            </InnerWrapper>
          )}
        </>
      )}

      <CloseButton
        dataTestId="add-modal-summary-close-button"
        onClick={onClose}
      >
        Close
      </CloseButton>
    </Wrapper>
  )
}

AddUserModalSummary.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
}

export default AddUserModalSummary
